/**
 * The dependency `intermediate_locales` is injected via Webpack (resolve.alias) and depends on a rake task to export Rails I18n locales to json
 * The default locale is stored in `window.default_locale` at compile-time, dynamic locale can be set in the `<html>` tag in application.html.erb
 */

import translations from 'intermediate_locales';

window.default_locale = 'en';

// `window.all_translations` is consumed by the Vue i18n helper `Vue.$t('...')`
window.all_translations = translations;

// `window.translations` is consumed by legacy vanilla-javascript helpers (like jQuery, DataTables)
// However, keys like `currency_symbols` are never translated and need to be merged with the actually translated keys
window.translations = {
  ...translations[window.default_locale],
  ...translations[document.documentElement.lang],
};
